import cn from 'classnames'
import { LinedGlobe } from '@rushable/icons'

export type TGBPTrackingProps = {
  className?: string
  websiteLink: boolean
}

export default function GBPTracking({
  className,
  websiteLink,
}: TGBPTrackingProps): JSX.Element {
  const cls = className?.includes('w-') ? className : `w-full ${className}`
  return (
    <div className={cn('ru-GBPTracking flex  items-center', cls)}>
      <LinedGlobe
        size={20}
        className={cn(websiteLink ? 'text-yellow' : 'text-dark-100')}
      />
    </div>
  )
}
