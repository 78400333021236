import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import StageFilter from './ConditionsFilterForms/StageFilter'
import BrandSearchFilter from './ConditionsFilterForms/BrandSearchFilter'
import SalesTeamFilter from './ConditionsFilterForms/SalesTeamFilter'
import DealCloserFilter from './ConditionsFilterForms/DealCloserFilter'
import OnboardingAssigneeFilter from './ConditionsFilterForms/OnboardingAssigneeFilter'
import OnboardingIssueFilter from './ConditionsFilterForms/OnboardingIssueFilter'
import GBPLinkIssueFilter from './ConditionsFilterForms/GBPLinkIssueFilter'
import FeatureOffFilter from './ConditionsFilterForms/FeatureOffFilter'
import PhoneFilter from './ConditionsFilterForms/PhoneFilter'

import { getSaleTeams, getSaleUsers, getAssignee } from 'redux/account'

import type { TFilterParams } from 'pages/Accounts/helpers'
import { IOptions } from 'components/Select/help'

export type TConditionsFormProps = {
  conditionsForm: TFilterParams
  onFormChange: (option: { name: string; value: any }) => void
}

export default function ConditionsForm({
  conditionsForm,
  onFormChange,
}: TConditionsFormProps): JSX.Element {
  const [teamOptions, setTeamOptions] = useState<IOptions[]>([])
  const [userOptions, setUserOptions] = useState<IOptions[]>([])
  const [assigneeOptions, setAssigneeOptions] = useState<IOptions[]>([])

  const {
    stage,
    brand_name,
    team_id,
    closer_id,
    assignee_id,
    issue,
    gbp,
    feature,
    phone,
  } = conditionsForm

  const onboardingAssigneeShow =
    stage === 'onboarding' ||
    stage === 'live' ||
    stage === 'churned' ||
    stage === 'withdrawal'
  const onboardingIssueShow =
    stage === 'onboarding' || stage === 'churned' || stage === 'withdrawal'
  const GBPLinkAndFeatureOffShow =
    stage === 'live' ||
    stage === 'cancelling' ||
    stage === 'churned' ||
    stage === 'withdrawal'

  const getTeams = async () => {
    try {
      setUserOptions([
        {
          label: '-',
          value: '',
        },
      ])
      const res = await getSaleTeams()
      if (res) {
        setTeamOptions(
          [
            {
              label: 'Any Team',
              value: '',
            },
          ].concat(
            res.map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              }
            }),
          ),
        )
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  const getUsers = async (id: string) => {
    try {
      const res = await getSaleUsers({
        crm_team_id: id,
      })
      if (res) {
        setUserOptions(
          [
            {
              label: '-',
              value: '',
            },
          ].concat(
            res.map((item: any) => {
              return {
                label: `${item.user.first_name} ${item.user.last_name}`,
                value: item.id,
              }
            }),
          ),
        )
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  const getAssigneeOptions = async () => {
    try {
      const res = await getAssignee()
      if (res && res.length > 0) {
        setAssigneeOptions(
          [
            {
              label: '-',
              value: '',
            },
          ].concat(
            res.map((item: any) => {
              return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
              }
            }),
          ),
        )
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  useEffect(() => {
    if (team_id) {
      getUsers(team_id)
    }
  }, [team_id])

  useEffect(() => {
    if (stage === 'onboarding' || stage === 'live' || stage === 'churned') {
      getAssigneeOptions()
    }
  }, [stage])

  useEffect(() => {
    getTeams()
  }, [])

  return (
    <div className='text-left flex flex-col space-y-4'>
      <StageFilter
        value={stage || ''}
        onChange={value => {
          onFormChange({
            name: 'stage',
            value,
          })
        }}
      />
      <BrandSearchFilter
        value={brand_name!}
        onChange={value => {
          onFormChange({
            name: 'brand_name',
            value,
          })
        }}
      />
      <SalesTeamFilter
        key={team_id}
        value={team_id || ''}
        options={teamOptions}
        onChange={value => {
          onFormChange({
            name: 'team_id',
            value,
          })
        }}
      />
      <DealCloserFilter
        key={`closer-${closer_id}`}
        value={closer_id || ''}
        options={userOptions}
        onChange={value => {
          onFormChange({
            name: 'closer_id',
            value,
          })
        }}
      />
      <PhoneFilter
        value={phone || ''}
        onChange={value => {
          onFormChange({
            name: 'phone',
            value,
          })
        }}
      />
      {onboardingAssigneeShow && (
        <OnboardingAssigneeFilter
          value={assignee_id || ''}
          options={assigneeOptions}
          onChange={value => {
            onFormChange({
              name: 'assignee_id',
              value,
            })
          }}
        />
      )}
      {onboardingIssueShow && (
        <OnboardingIssueFilter
          value={issue || ''}
          onChange={value => {
            onFormChange({
              name: 'issue',
              value,
            })
          }}
        />
      )}
      {GBPLinkAndFeatureOffShow && (
        <>
          <GBPLinkIssueFilter
            value={gbp || ''}
            onChange={value => {
              onFormChange({
                name: 'gbp',
                value,
              })
            }}
          />
          <FeatureOffFilter
            value={feature || ''}
            onChange={value => {
              onFormChange({
                name: 'feature',
                value,
              })
            }}
          />
        </>
      )}
    </div>
  )
}
