import { useState, useEffect } from 'react'
import AlertText from 'components/AlertText'
import CommunicationLogItem from 'components/CommunicationLogItem'
import Label from 'components/Label'
import Button from 'components/Button'
import CommentModal from './CommentModal'
import AttentionModal from './AttentionModal'

export type Tprops = {
  tab?: 'Onboarding' | 'Success'
  onboardingId: string
  attention: string
  disabled?: boolean
  showCommentBtn?: boolean
  logs: any[]
  loading?: boolean
  onSuccess: () => void
  onUpdateComment: (value?: string) => void
}

export default function CommunicationList({
  tab = 'Onboarding',
  onboardingId,
  attention,
  disabled,
  showCommentBtn,
  logs,
  loading,
  onSuccess,
  onUpdateComment,
}: Tprops) {
  const [showComment, setShowComment] = useState(false)
  const [communtations, setCommuntations] = useState<any[]>([])
  const [comment, setComment] = useState<Record<string, any>>({})
  useEffect(() => {
    const communtations = logs?.map((item: any) => ({
      id: item.id,
      authorId: item.author_id,
      msg: item.content,
      updateAt: item.updated_at,
      mode: item.message_type,
      source: item.direction,
      name: item.user_name,
      callUrl: item.link,
      avatar: item.author?.crm_user?.profile_photo,
      callTime: item.duration, // 电话形式的时间
      remindAt: item?.remind_at || null,
    }))
    setCommuntations(communtations)
  }, [logs])
  return (
    <>
      <div className='flex mb-6'>
        {tab === 'Onboarding' && (
          <AttentionModal
            className='flex-1 mr-4'
            disabled={disabled}
            attention={attention}
            onboardingId={onboardingId}
            onSuccess={onSuccess}
          />
        )}
        <div className='flex-1'>
          <Label>{tab === 'Onboarding' ? 'COMMUNICATION' : 'COMMUNICATION LOG'}</Label>
          <Button
            className='w-full mt-2'
            color='secondary'
            theme='outlined'
            disabled={disabled}
            onClick={() => {
              setShowComment(true)
              setComment({})
            }}
          >
            ADD COMMENT
          </Button>
        </div>
      </div>
      {tab === 'Onboarding' && attention && (
        <AlertText className='mb-6 w-full' icon={false} color='yellow' text={attention} />
      )}
      <div>
        {communtations?.map((item, index) => {
          return (
            <CommunicationLogItem
              key={index}
              disabled={disabled}
              className='mb-6'
              {...item}
              onCommentUpdated={() => {
                setShowComment(true)
                setComment(item)
              }}
            />
          )
        })}
        {showCommentBtn && (
          <Button
            className='mt-4 mx-auto'
            theme='outlined'
            color='secondary'
            onClick={() => onUpdateComment()}
            loading={loading}
          >
            Show earlier activities
          </Button>
        )}
      </div>
      <CommentModal
        onboardingId={onboardingId}
        comment={comment}
        open={showComment}
        toggle={() => setShowComment(!showComment)}
        onSuccess={() => onUpdateComment('')}
      />
    </>
  )
}
