import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  getAccountDetailApi,
  emptyAccountDetail,
  setOnlySelf,
  setMode,
  setFilterCount,
  setFilterParams,
  setPage,
  setPageSize,
  setTotal,
  setData,
  setCounts,
  setOrder,
  setStartState,
  setNeedReload,
} from './action'
import { setGroupFormData, setGroupOpenIndex, setGroupTable } from './group'
import type { TMode } from 'components/StageSelect'
import type {
  TFilterParams,
  TCount,
  TOrder,
  TData,
  TGroupFormData,
  TGroupTable,
} from 'pages/Accounts/helpers'

type Tstate = {
  initLoading: boolean
  detail: Record<string, any>
  errors: string
  onlySelf: string
  mode: TMode
  filterCount: number
  filterParams: TFilterParams
  page: number
  pageSize: number
  total: number
  data: TData
  counts: TCount
  sortData: TOrder
  needReload: boolean
  // group
  groupFormData: TGroupFormData
  groupTable: TGroupTable
  groupOpenIndex: number | null
}

const initialState: Tstate = {
  initLoading: true,
  detail: {},
  errors: '',
  onlySelf: '0',
  mode: 'in_sales',
  filterCount: 0,
  filterParams: {
    page: 1,
    brand_name: '',
    stage: 'in_sales',
    team_id: '',
    closer_id: '',
    issue: '',
    gbp: '',
    feature: '',
    assignee_id: '',
    phone: '',
  },
  page: 0,
  pageSize: 20,
  total: 0,
  data: [],
  counts: {
    churned: 0,
    withdrawal: 0,
    canceling: 0,
    draft: 0,
    live: 0,
    onboarding: 0,
    signup: 0,
    all: 0,
  },
  sortData: {
    sort_by: '',
    sort: '',
  },
  needReload: false,
  groupFormData: {
    only_self: '1',
    brand_name: '',
    page: 1,
  },
  groupTable: {
    total: 0,
    data: [],
    currentPage: 1,
    perPage: 20,
  },
  groupOpenIndex: null,
}

export const accountDetailReducer = createReducer(initialState, builder => {
  builder
    // account detail 部分
    .addCase(getAccountDetailApi.pending, state => {
      state.initLoading = true
    })
    .addCase(getAccountDetailApi.fulfilled, (state, action) => {
      state.initLoading = false
      state.detail = action.payload || {}
      state.errors = ''
    })
    .addCase(getAccountDetailApi.rejected, (state, action) => {
      state.initLoading = false
      toast.error(action.error.message)
      state.errors = action.error.message || ''
    })
    // 在 detail 页面销毁时清空 detail 内的数据
    .addCase(emptyAccountDetail, (state, action) => {
      state.initLoading = true
      state.detail = {}
      state.errors = ''
    })
    // account detail 部分
    .addCase(setStartState, (state, action) => {
      const params = action.payload
      if (params) {
        if (params?.only_self === '1') {
          state.onlySelf = '1'
        }
        if (params?.stage) {
          state.mode = params?.stage
        }
        state.filterParams = {
          page: params?.page || 1,
          brand_name: params?.brand_name || '',
          stage: params?.stage || '',
          team_id: params?.team_id || '',
          closer_id: params?.closer_id || '',
          issue: params?.issue || '',
          gbp: params?.gbp || '',
          feature: params?.feature || '',
          assignee_id: params?.assignee_id || '',
          phone: params?.phone || '',
        }
      }
    })
    .addCase(setOnlySelf, (state, action) => {
      state.onlySelf = action.payload
    })
    .addCase(setMode, (state, action) => {
      state.mode = action.payload
    })
    .addCase(setFilterCount, (state, action) => {
      state.filterCount = action.payload
    })
    .addCase(setFilterParams, (state, action) => {
      state.filterParams = action.payload
    })
    .addCase(setPage, (state, action) => {
      state.page = action.payload
    })
    .addCase(setPageSize, (state, action) => {
      state.pageSize = action.payload
    })
    .addCase(setTotal, (state, action) => {
      state.total = action.payload
    })
    .addCase(setData, (state, action) => {
      state.data = action.payload
    })
    .addCase(setCounts, (state, action) => {
      state.counts = action.payload
    })
    .addCase(setOrder, (state, action) => {
      state.sortData = action.payload
    })
    .addCase(setNeedReload, (state, action) => {
      state.needReload = action.payload
    })
    // group
    .addCase(setGroupFormData, (state, action) => {
      state.groupFormData = action.payload
    })
    .addCase(setGroupTable, (state, action) => {
      state.groupTable = action.payload
    })
    .addCase(setGroupOpenIndex, (state, action) => {
      state.groupOpenIndex = action.payload
    })
})

export default accountDetailReducer
