import { useState } from 'react'
import { LinedChatComment, LinedCalendar } from '@rushable/icons'
import DateAndTime from 'components/DateAndTime'
import IconButton from 'components/IconButton'
import moment from 'moment'

export type TCommentItemProps = {
  name: string
  updateAt: string
  msg: string
  remindAt: string | null
}

export default function CommentItem({
  name,
  updateAt,
  msg,
  remindAt,
}: TCommentItemProps): JSX.Element {
  const [open, setOpen] = useState(false)
  return (
    <div className='flex-1 px-4 py-2 bg-dark-900 border border-dark-100 rounded-lg '>
      <div className='flex justify-between mb-2'>
        <div className='flex flex-1 items-center'>
          <IconButton
            className='mr-1'
            icon={<LinedChatComment size={16} className='text-light-500' />}
            size='small'
            hasHover={false}
          />
          {!!remindAt && (
            <div
              className='relative'
              onMouseOver={() => {
                setOpen(true)
              }}
              onMouseOut={() => setOpen(false)}
            >
              {open && (
                <div className='w-max px-4 py-2 absolute bottom-6 border border-dark-100 rounded-lg bg-dark-500'>
                  Reminder scheduled: {moment(remindAt).format('MMM DD @ hh:mm A')}
                </div>
              )}
              <IconButton
                className='mr-1'
                icon={<LinedCalendar size={16} className='text-yellow' />}
                size='small'
                hasHover={false}
              />
            </div>
          )}
          <span className='text-xs text-light-500 pt-0.5'>{name}</span>
        </div>
        <DateAndTime
          className='h-5 !text-light-100 text-xs px-0'
          value={updateAt}
          format='YYYY MMM DD @ hh:mm A'
        />
      </div>
      <div className='break-all	text-xs text-light-500 whitespace-pre-wrap'>{msg}</div>
    </div>
  )
}
