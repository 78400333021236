import { useRef, useCallback } from 'react'

/**
 * useThrottle Hook
 * @param fn - 请求逻辑的回调函数
 * @param interval - 最小调用间隔（毫秒）
 * @returns throttledHandler - 经过节流的触发函数
 */
const useThrottle = (fn: () => void, interval: number) => {
  const lastRequestTimeRef = useRef(0) // 上一次请求的时间戳
  const pendingRef = useRef(false) // 是否有未处理的事件
  const timerRef = useRef<NodeJS.Timeout | null>(null) // 当前激活的定时器

  const throttledHandler = useCallback(() => {
    const now = Date.now()

    if (now - lastRequestTimeRef.current >= interval) {
      // 如果超过最小间隔，立即执行请求
      fn?.()
      lastRequestTimeRef.current = now
      pendingRef.current = false

      // 清除定时器（如果存在）
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    } else {
      // 未达到间隔，标记有待处理的事件
      pendingRef.current = true

      // 如果没有激活定时器，设置一个定时器来延迟执行
      if (!timerRef.current) {
        timerRef.current = setTimeout(() => {
          if (pendingRef.current) {
            fn?.()
            lastRequestTimeRef.current = Date.now()
            pendingRef.current = false
          }
          timerRef.current = null // 重置定时器
        }, interval - (now - lastRequestTimeRef.current))
      }
    }
  }, [fn, interval])

  return throttledHandler
}

export default useThrottle
