import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import SearchFilter from './components/SearchFilter'
import OrdersTable from './components/OrdersTable'
import { getOrders } from 'redux/order'
import moment from 'moment'

import { TFilterForm } from './helpers/constant'
import { IOptions } from 'components/Select/help'
import PageLoading from 'components/PageLoading'
import pusher from 'utils/pusher'
import useThrottle from 'hooks/useThrottle'

export default function OrdersPage() {
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(40)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [autoLoading, setAutoLoading] = useState(false)

  const [filterForm, setFilterForm] = useState<TFilterForm>({
    status: '',
    brand_name: '',
    location_id: '',
    type: '',
    begin_time: '',
    end_time: '',
    page: 1,
  })
  const [locationOptions, setLocationOptions] = useState<IOptions[]>([
    { value: '', label: 'All Locations' },
  ])
  const channel = pusher.subscribe('order')

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'page' ? value : 1
    let newForm: TFilterForm = { ...filterForm }

    if (type === 'dates') {
      newForm = {
        ...filterForm,
        page,
        begin_time: !value.startDate
          ? ''
          : moment(value.startDate)
              ?.startOf('day')
              ?.add(3, 'hours')
              ?.format('YYYY-MM-DD HH:mm:ss'),
        end_time: !value.endDate
          ? ''
          : moment(value.endDate)
              ?.endOf('day')
              ?.add(3, 'hours')
              ?.format('YYYY-MM-DD HH:mm:ss'),
      }
    } else if (type === 'brand_name') {
      newForm = {
        ...filterForm,
        page,
        brand_name: value,
        location_id: '',
      }
    } else {
      newForm = {
        ...filterForm,
        page,
        [type]: value,
      }
    }
    setFilterForm(newForm)
    getTable(newForm)
  }
  const convertStateToParams = (fp: TFilterForm) => {
    let filterParams = fp ? fp : filterForm
    let params: any = {
      ...filterParams,
      brand_name: filterParams.brand_name === 'All Brands' ? '' : filterParams.brand_name,
    }
    return params
  }
  const getTable = async (form: TFilterForm, isAutoUpdate?: boolean) => {
    // 请求 api
    const filterParams = form ? form : filterForm
    isAutoUpdate ? setAutoLoading(true) : setLoading(true)
    try {
      localStorage.setItem('live_order_filters', JSON.stringify(filterParams))
      const res = await getOrders(convertStateToParams(filterParams))
      isAutoUpdate ? setAutoLoading(false) : setLoading(false)
      setData(res.data)
      setTotal(res.total)
      setPageSize(res.per_page)
    } catch (e: any) {
      isAutoUpdate ? setAutoLoading(false) : setLoading(false)
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }
  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  const fetchWithUpdate = () => {
    const orderFilters = localStorage.getItem('live_order_filters')
    const tp = orderFilters ? JSON.parse(orderFilters) : null
    getTable(tp, true)
  }

  const throttledFetchWithUpdate = useThrottle(fetchWithUpdate, 30000)

  useEffect(() => {
    getTable(filterForm)
    //Handler for the pusher event
    channel.bind('order_updated', () => {
      throttledFetchWithUpdate()
    })
    channel.bind('order_created', () => {
      throttledFetchWithUpdate()
    })
    return () => {
      channel.unbind('order_updated')
      channel.unbind('order_created')
    }
  }, [])
  return (
    <AdminLayout>
      <div
        style={{ maxHeight: loading ? 'calc(100vh - 80px)' : 'none' }}
        className={`relative ${loading && 'overflow-y-hidden'}`}
      >
        {loading && (
          <div className='bg-dark-900 w-full h-full absolute z-40'>
            <PageLoading />
          </div>
        )}

        <div className='px-10 py-8'>
          <SearchFilter
            autoLoading={autoLoading}
            locationOptions={locationOptions}
            setLocationOptions={setLocationOptions}
            filterForm={filterForm}
            handleFormChange={handleFormChange}
          />
          <OrdersTable
            total={total}
            current={filterForm.page}
            pageSize={pageSize}
            data={data}
            onPageNoChange={onPageNoChange}
          />
        </div>
      </div>
    </AdminLayout>
  )
}
