import cn from 'classnames'
import { LinedPenEdit } from '@rushable/icons'
import PersonName from 'components/PersonName'
import CallItem from './CallItem'
import SmsItem from './SmsItem'
import EmailItem from './EmailItem'
import CommentItem from './CommentItem'
import Button from 'components/Button'

import { useAppSelector } from 'redux/hooks'

export type TCommunicationLogItemProps = {
  className?: string
  disabled?: boolean
  id?: string
  authorId?: number
  source: 'incoming' | 'outgoing'
  mode: 'phone' | 'sms' | 'email' | 'comment'
  name: string
  avatar?: string
  msg?: string
  callTime?: number | string
  callUrl?: string
  updateAt: string
  remindAt?: string | null
  onCommentUpdated?: (id: string, msg: string) => void
}

export default function CommunicationLogItem({
  className,
  disabled,
  id,
  authorId,
  source,
  mode,
  name,
  avatar,
  msg,
  callTime,
  callUrl,
  updateAt,
  remindAt,
  onCommentUpdated,
}: TCommunicationLogItemProps): JSX.Element {
  const profile = useAppSelector(state => state.auth.profile)
  return (
    <div
      className={cn(
        'w-full flex',
        source === 'incoming' ? 'pr-8' : 'flex-row-reverse pl-8',
        className,
      )}
    >
      <div
        className={cn(
          'flex flex-col items-center',
          source === 'incoming' ? 'mr-2' : 'ml-2',
        )}
      >
        <PersonName hasName={false} avatar={avatar} />
        {mode === 'comment' && authorId === profile?.id ? (
          <Button
            theme='text'
            className='mt-1'
            disabled={disabled}
            onClick={() => onCommentUpdated?.(id as string, msg as string)}
          >
            <LinedPenEdit size={16} className='text-light-500' />
          </Button>
        ) : null}
      </div>
      {mode === 'phone' && (
        <CallItem
          source={source}
          name={name}
          callTime={callTime}
          callUrl={callUrl}
          updateAt={updateAt}
        />
      )}
      {mode === 'sms' && (
        <SmsItem source={source} name={name} updateAt={updateAt} msg={msg as string} />
      )}
      {mode === 'email' && (
        <EmailItem source={source} name={name} updateAt={updateAt} msg={msg as string} />
      )}
      {mode === 'comment' && (
        <CommentItem
          name={name}
          updateAt={updateAt}
          msg={msg as string}
          remindAt={remindAt || null}
        />
      )}
    </div>
  )
}
